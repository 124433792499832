import React from 'react'
import Header from '../controller/header'
// import Loader from '../controller/Loader'

export default function StepAliPay({
    // setImage,
    alipayFullname,
    alipayId,
    previewUrl,
    setPreviewUrl,
    handleIsPrev,
    handleAlipayNameChange,
    handleAliIDChange,
    handleNext,
    handlePrev,
    isLoading,
}) {


    const handleImageChange = (e) => {
        try {
            const file = e.target.files && e.target.files[0];
            if (file) {
                // Set the image state
                // setImage(file);

                // Read the file as a data URL
                const reader = new FileReader();
                reader.onload = () => {
                    // Set the preview URL to display the image
                    setPreviewUrl(reader.result);
                };
                reader.readAsDataURL(file);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const OnValidate = () => {
        if (previewUrl && alipayFullname && alipayId) {
            handleNext();
        }
    }
    return (
        <div className='main-section' >
            <div className='mainContainer' >
                <div className='content' style={{ paddingTop: 0 }}>
                    <div className='parentContainer'>
                        <Header size={17} onBackClick={handlePrev} icon title="Alipay / WeChat QR code" />

                        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                            <div style={{ flex: '1 1 auto' }}>

                                <div className='inputCove' style={{ marginTop: 15, padding: 5, justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>

                                    <div className='upload-container'>
                                        <div  >
                                            <label for="uploadfile" >Click here your QR-Code here!</label>
                                        </div>
                                    </div>

                                    {previewUrl && (
                                        <img alt="https://qashless.app/icons/yuanpayLogo.png"
                                            src={previewUrl}
                                            // alt="Preview"
                                            style={{ maxWidth: '100px', maxHeight: '100px' }}
                                        />
                                    )}
                                    <input onChange={(e) => handleImageChange(e)} id="uploadfile" type='file' style={{ display: 'none' }} />

                                </div>


                                <div style={{ marginBottom: 15 }}>
                                    <span style={{ fontSize: 13 }}>Max file size: 2.86 MB. | Allowed file types: jpeg,png,jpg </span>
                                </div>


                                <div className='inputCover solid-border' style={{ marginTop: 15, marginBottom: 15, padding: 5, backgroundColor: 'transparent', }}>

                                    <span style={{ fontSize: 13 }}>Full Name on Alipay Account*</span>
                                    <div className='column'>
                                        <input style={{ backgroundColor: 'transparent' }} required className='text-input-details' type="text" value={alipayFullname} onChange={(e) => handleAlipayNameChange(e)} placeholder="Write here" />

                                    </div>
                                </div>

                                <div className='inputCover solid-border' style={{ marginTop: 15, marginBottom: 15, padding: 5, backgroundColor: 'transparent' }}>

                                    <span style={{ fontSize: 13 }}>Alipay Number/Email*</span>
                                    <div className='column'>
                                        <input style={{ backgroundColor: 'transparent' }} required className='text-input-details' type="text" value={alipayId} onChange={(e) => handleAliIDChange(e)} placeholder="Write here" />

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='Button-container' style={{ alignSelf: 'flex-end' }}>
                            <button className='btn-continue btn-bg' onClick={() => OnValidate()}>Continue</button>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}
