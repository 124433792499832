import React, { useState } from 'react'
import Loader from '../controller/Loader'
import exponent from '../controller/exponent';
import CurrencyInput from 'react-currency-input-field';

export default function Step1({ amount, handleAmountChange, handleNext, isLoading, isRate, }) {


    // const [amount, setAmount] = useState(100);
    // const activeSteps = [1, 2];

    const [step, setStep] = useState(1)
    // const [iLoading, setILoading] = useState(false);
    const [currentAction, setCurrentAction] = useState('buy');

    const buy = (action) => {
        setCurrentAction(action);
        setStep(1);

    }
    const sell = (action) => {

        setCurrentAction(action);
        setStep(2);
    };

    const { Rate } = isRate[0] || {};
    const xAmount = amount * Rate;

    const OnValidate = () => {
        if (!isNaN(amount) && amount && amount >= 100 && xAmount) {
            handleNext()
        } else {

        }
    }

    const renderStep = () => {
        switch (step) {
            case 1:
                return (

                    <div className='trade-content'>
                        <h5 className="title-mobile">How much Yuan would you like to buy?</h5>

                        <div className="amount-input">
                            <div className='column' style={{ alignItems: 'normal', justifyContent: 'normal' }}>

                                <CurrencyInput
                                    prefix="¥&nbsp;"
                                    className='input'
                                    autoFocus placeholder="¥&nbsp;0"
                                    defaultValue={amount}
                                    decimalsLimit={2}
                                    onValueChange={(value) => handleAmountChange(value)}
                                />
                            </div>
                        </div>


                        <ul><li className="select-currency">
                            <div className="left"><p className="title">Buy</p></div>
                            <div className="select-custom">
                                <label >
                                    <img alt="https://qashless.app/icons/yuanpayLogo.png" src='https://qashless.app/icons/RMB.png' height={25} width={25} style={{ borderRadius: 100, marginRight: 10 }} />
                                </label>
                                <select id="currency-select">
                                    <option value="NGN">RMB</option>
                                </select>
                            </div>
                        </li>
                            <li className="select-currency"><div className="left">
                                <p className="title">Pay with</p></div>
                                <div className="select-custom">
                                    <label for="currency-select">
                                        <img alt="https://qashless.app/icons/yuanpayLogo.png" src='https://qashless.app/icons/ng.png' height={25} width={25} style={{ borderRadius: 100, marginRight: 10 }} />
                                    </label>
                                    <select id="currency-select">
                                        <option value="NGN">Naira</option>
                                    </select>
                                </div>
                            </li></ul>

                        <div className='inputCover2' style={{ marginTop: 12 }}>
                            <div data-info="This is the estimated rate you will pay in your local currency to buy crypto. Prices change due to market volatility." className="rate-info">
                                <img alt="https://qashless.app/icons/yuanpayLogo.png" src='https://qashless.app/icons/RMB.png' height={20} width={20} style={{ borderRadius: 100, marginRight: 10 }} />  1 Yuan =NGN&nbsp;{exponent(Rate, 2)}
                            </div>
                            <div className='column'>
                                <div className='lableContainer'>
                                    <span className='filedLable'>You will receive</span>
                                </div>
                                <span>NGN {exponent(xAmount, 2)}</span>
                            </div>
                        </div>

                    </div>
                )
            case 2:
                return (

                    <div className='trade-content'>
                        <h5 className="title-mobile">How much Yuan would you like to buy?</h5>

                        <div className="amount-input">
                            <div className='column' style={{ alignItems: 'normal', justifyContent: 'normal' }}>

                                <CurrencyInput
                                    prefix="NGN&nbsp;"
                                    className='input'
                                    autoFocus placeholder="NGN&nbsp;0"
                                    defaultValue={amount}
                                    decimalsLimit={2}
                                    onValueChange={(e) => handleAmountChange(e)}
                                    maxLength={10}
                                />
                            </div>
                        </div>


                        <ul><li className="select-currency">
                            <div className="left"><p className="title">Sell</p></div>
                            <div className="select-custom">
                                <label for="currency-select">
                                    <img alt="https://qashless.app/icons/yuanpayLogo.png" src='https://qashless.app/icons/RMB.png' height={25} width={25} style={{ borderRadius: 100, marginRight: 10 }} />
                                </label>
                                <select id="currency-select">
                                    <option value="NGN">RMB</option>
                                </select>
                            </div>
                        </li>
                            <li className="select-currency"><div className="left">
                                <p className="title">Receive</p></div>
                                <div className="select-custom">
                                    <label for="currency-select">
                                        <img alt="https://qashless.app/icons/yuanpayLogo.png" src='https://qashless.app/icons/ng.png' height={25} width={25} style={{ borderRadius: 100, marginRight: 10 }} />
                                    </label>
                                    <select id="currency-select">
                                        <option value="NGN">Naira</option>
                                    </select>
                                </div>
                            </li></ul>

                        <div className='inputCover2' style={{ marginTop: 12 }}>
                            <div className='column'>
                                <div className='lableContainer'>
                                    <span className='filedLable'>You will receive</span>
                                </div>
                                <span>NGN {exponent(xAmount, 2)}</span>
                            </div>
                        </div>


                    </div>
                );
        }

    }
    return (
        <div className='main-section' >

            <div className='mainContainer' >

                <div className='content' style={{ paddingTop: 0 }}>
                    {/* <Header title=" Payment Gatway" /> */}
                    <div className='trade-nav'>
                        <a href="javascript:void(0)" data-cy="buy-nav"
                            className={currentAction === 'buy' ? 'current' : ''}
                            onClick={() => buy('buy')}>Buy</a>
                        <a href="javascript:void(0)" data-cy="Sell-nav"
                            className={currentAction === 'sell' ? 'current' : ''}
                            onClick={() => sell('sell')} >Sell</a>
                    </div>
                    {renderStep()}

                    {step === 1 ? (
                        <div className='Button-container' style={{ alignSelf: 'flex-end' }}>
                            <button className='btn-continue btn-bg' onClick={() => OnValidate()}>Pay Now</button>
                        </div>) :

                        (<div className='Button-container' style={{ alignSelf: 'flex-end' }}>
                            <button className='btn-continue'>Coming Soon</button>
                        </div>)
                    }


                </div>
            </div>
            {isLoading && <Loader />}
        </div>
    )
}
