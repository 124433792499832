// function handleAmountChange(event) {
//     const rawAmount = event.target.value.replace(/[^0-9.]/g, ''); // Remove non-numeric and non-decimal characters
//     const formattedAmount = formatAmount(rawAmount);
//     event.target.value = formattedAmount;
// }

// const exponent = (amount, to) => {
//     try {
//         const precision = to;
//         const separator = '.';
//         const delimiter = ',';


//         const parts = amount.split('.');
//         const integerPart = parts[0];
//         const decimalPart = parts[1] || '';

//         const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
//         const formattedDecimalPart = decimalPart.slice(0, precision).padEnd(precision, '0');

//         return formattedIntegerPart + separator + formattedDecimalPart;
//     } catch {
//         return "0"; // Return "0" for any error cases
//     }
// };

const exponent = (amount, to) => {
    try {
        // Parse the amount as a float, default to 0 if it's not a valid number
        let isAmount = `${amount}`
        const parsedAmount = parseFloat(!isAmount || isNaN(isAmount) ? 0 : `${isAmount}`);

        // Format the amount with the specified decimal places
        const formattedAmount = parsedAmount.toFixed(to);

        // Check if the amount is zero and add .00 in that case
        if (parsedAmount === 0) {
            return '0.00';
        }
        // Check if dot or point is entered, then decide whether to include decimal places
        const hasDecimal = isAmount && (isAmount.includes('.') || isAmount.includes(','));

        if (hasDecimal) {
            // Add commas to the integer part of the number
            return formattedAmount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        } else {
            // Remove trailing decimal places and add commas to the integer part
            return formattedAmount.replace(/\.\d*$/, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
    } catch {
        return '';
    }
};

export default (amount, to) => {
    return exponent(amount, to);
};