import React from 'react'
import Header from '../controller/header'
import Loader from '../controller/Loader'

export default function Login({
    handleNext,
    handlePrev,
    isLoading,
    handleReg,
    email,
    name,
    phoneNumber,
    handleNameChange,
    handleChangePhoneNumber,
    handleEmailChange
}) {

    // const [email, setEmail] = useState('')
    // const [fullname, setFullname] = useState('');
    // const [phoneNumber, setphoneNumber] = useState('');
    // const [show, setShow] = useState(false)

    // const handleEmailChange = (e) => {

    //     setEmail(e.target.value);

    // }

    // const handleChangeFullname = (e) => {

    //     setFullname(e.target.value);

    // }


    // const handleChangePhoneNumber = (e) => {

    //     setphoneNumber(e.target.value);

    // }

    // const handleShowPassword = () => {

    //     setShow(!show)
    // }

    const OnValidate = () => {
        if (name && email && phoneNumber) {
            handleNext();
        }
    }
    return (

        <div className='main-section' >
            <div className='mainContainer' >
                <div className='content' style={{ paddingTop: 0 }}>
                    <div className='parentContainer details-cover'>
                        <Header size={17} onBackClick={handlePrev} icon title="Payment information" />

                        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                            <div style={{ flex: '1 1 auto' }}>

                                <div className='text-container'>
                                    {/* <div>
                            <span className='small-header'>Know your Customer</span>
                        </div> */}
                                    <div>
                                        {/* <span className='small-text'>Enter your full name as seen on your bank payment details</span> */}
                                        <span className='small-text'>Note: The entered name must match the one used for your payment.  </span>
                                    </div>

                                </div>

                                <div style={{ marginTop: 10 }}>
                                    <div className='column'>
                                        <span style={{ fontSize: 13 }}>Full Name </span>
                                        <span className='color-red'>*</span>
                                    </div>

                                    <div className='inputCover solid-border' style={{ marginTop: 3, marginBottom: 15, padding: 5, backgroundColor: 'transparent' }}>
                                        <div className='column'>
                                            <input required
                                                style={{ backgroundColor: 'transparent' }}
                                                className='text-input-details'
                                                type="text"
                                                value={name}
                                                onChange={handleNameChange}
                                                placeholder="John Doe" />

                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='column'>
                                        <span style={{ fontSize: 13 }}>Email </span>
                                        <span className='color-red'>*</span>
                                    </div>

                                    <div className='inputCover solid-border' style={{ marginTop: 3, marginBottom: 15, padding: 5, backgroundColor: 'transparent' }}>
                                        <div >
                                            <input
                                                required
                                                style={{ backgroundColor: 'transparent' }}
                                                className='text-input-details'
                                                type={"email"}
                                                onChange={handleEmailChange}
                                                value={email}
                                                placeholder='John@youremail.com'
                                            />

                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div className='column'>
                                        <span style={{ fontSize: 13 }}>Whatsapp Number</span>
                                        <span className='color-red'>*</span>
                                    </div>

                                    <div className='inputCover solid-border' style={{ marginTop: 3, marginBottom: 15, padding: 5, backgroundColor: 'transparent' }}>
                                        <div >
                                            <input required
                                                style={{ backgroundColor: 'transparent' }}
                                                className='text-input-details'
                                                type={"text"}
                                                onChange={handleChangePhoneNumber}
                                                value={phoneNumber}
                                                placeholder='09112345678'
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='Button-container' style={{ alignSelf: 'flex-end' }}>
                            <button className='btn-continue btn-bg' onClick={() => OnValidate()}>Next</button>
                        </div>
                        {/* 
            <div style={{ textAlign: "center" }}>
                <span style={{ fontSize: 13 }} className='black-color'>Dont have an account ? </span>
                <button onClick={handleReg} className='green-color' style={{ fontSize: 13, backgroundColor: 'transparent', border: 0 }}>Sign up</button>
            </div> */}
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}

        </div>
    )
}
