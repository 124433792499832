import React from 'react'
import Header from '../controller/header'
import Loader from '../controller/Loader'

export default function Step3({ handleNext, handlePrev, name, email, isLoading }) {
    // const [step, setStep] = useState('')
    // const renderStep = () => {
    //     switch (step) {


    //     }

    // }

    return (

        <div className='main-section' >
            <div className='mainContainer' >
                <div className='content' style={{ paddingTop: 0 }}>
                <div className='parentContainer details-cover'>
                    <Header onBackClick={handlePrev} icon title="Payment Option" />
                   


                        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                            <div style={{ flex: '1 1 auto' }}>

                                <div className='text-container'>
                                    <div>
                                        <span className='small-header'>How do you want to pay ?</span>
                                    </div>
                                    <div>
                                        <span className='small-text'>select a payment method to see for more information to Proceed</span>
                                    </div>

                                </div>


                                <div className='inputCover' style={{ padding: 5, marginTop: 15 }} onClick={() => handleNext()}>
                                    <div className='lableContainer'>
                                        <span className='filedLable' >Active </span>
                                    </div>
                                    <div className='lableContainer'>
                                        <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                                            <i className='fa fa-bank'></i>
                                            <div>
                                                <span>Bank Transfer</span>
                                            </div>

                                        </div>
                                        <div style={{ padding: 5, marginTop: 10 }}>
                                            {/* <div className='small-text'>you'll pay NGN 150 in fees</div> */}
                                            <span className='small-text'>Bank Transfer usally take few minutes to be confirmed</span>
                                            {/* <input type="text" value={name} onChange={handleNameChange} placeholder="Your Name" />
            <input type="email" value={email} onChange={handleEmailChange} placeholder="Your Email" /> */}
                                            {/* <button onClick={() => handleNext()}>Next</button> */}

                                        </div>
                                    </div>
                                </div>



                                <div className='inputCover' style={{ padding: 5, marginTop: 15 }}>
                                    <div className='lableContainer'>
                                        <span className='filedLable'>Coming soon </span>
                                    </div>
                                    <div className='lableContainer'>
                                        <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
                                            <img alt="https://qashless.app/icons/yuanpayLogo.png" src='https://static.moonpay.com/widget/currencies/usdt_trx.svg' width={30} height={30} />
                                            <div>
                                                <span>Digital Dollar</span>
                                            </div>

                                        </div>
                                        <div style={{ padding: 5, marginTop: 10 }}>
                                            {/* <div className='small-text'>you'll pay NGN 150 in fees</div> */}
                                            <span className='small-text'>Payment with usdt might delay depending on the network chain</span>
                                            {/* <input type="text" value={name} onChange={handleNameChange} placeholder="Your Name" />
            <input type="email" value={email} onChange={handleEmailChange} placeholder="Your Email" /> */}
                                            {/* <button onClick={() => handleNext()}>Next</button> */}

                                        </div>
                                    </div>
                                </div>
                                {/* 
                    <div style={{height:60}}></div> */}
                            </div>
                        </div>
                        {/* <div className='Button-container' style={{ alignSelf: 'flex-end' }}>
                <button className='btn-continue' onClick={() => handleNext()}>Continue</button>
            </div> */}
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
        </div>
    )
}
