
import './App.css';
import AppPayment from './widget/AppPayment';
import '../src/style/widget.css'
function App() {
  return (
    <div>
      <div >

        <section >
          <AppPayment />
        </section>
        {/* <footer className='footerContainer'>
        <div>
            <span style={{ fontSize: 12, color: "#101010" }}>Power by Schola Technology Limited</span>
        </div>
    </footer> */}


      </div>
      {/* <div>
        <span>If you have any questions, contact myschola.app@gmail.com</span>
        <span className='email-link '>myschola.app@gmail.com</span>
    </div> */}
    </div>
  );
}

export default App;
