import React, { useEffect } from 'react'
import Header from '../controller/header'
import Loader from '../controller/Loader'
import exponent from '../controller/exponent'

export default function Step4({ data, amount, isRata, handleSubmit, handlePrev, phone, handleNext, isLoading, orderId, generateOrderId }) {

    const { Bankname, AccountName, AccountNumber } = data[0] || {}

    const { Rate, fee } = isRata[0] || {}
    const isFee = parseInt(fee)
    const exAmount = parseFloat(amount * Rate + isFee);


    useEffect(() => {
        generateOrderId()
    }, [])
    const onSubmit = () => {
        if (!isNaN(amount) && amount && exAmount >= 100) {
            handleSubmit()
        }
    }

    const copyToAccount = (value) => {
        if (value) {
            navigator.clipboard.writeText(value).then(() => {
                alert('Copied to clipboard!');
            }, (err) => {
                console.error('Could not copy text: ', err);
            });
        }
    };

    // const copyToName = (value) => {
    //     if (value) {
    //         navigator.clipboard.writeText(value).then(() => {
    //             alert('Order ID copied to clipboard!');
    //         }, (err) => {
    //             console.error('Could not copy text: ', err);
    //         });
    //     }
    // };

    // const copyToOrderId = (value) => {
    //     if (value) {
    //         navigator.clipboard.writeText(value).then(() => {
    //             alert('Order ID copied to clipboard!');
    //         }, (err) => {
    //             console.error('Could not copy text: ', err);
    //         });
    //     }
    // };

    // const copyToClipboard = () => {
    //     if (orderId) {
    //         navigator.clipboard.writeText(orderId).then(() => {
    //             alert('Order ID copied to clipboard!');
    //         }, (err) => {
    //             console.error('Could not copy text: ', err);
    //         });
    //     }
    // };


    return (

        <div className='main-section' >
            <div className='mainContainer' >
                <div className='content' style={{ paddingTop: 0 }}>
                    <div className='parentContainer details-cover'>
                        <Header size={14} onBackClick={handlePrev} icon title="Make your deposit to our bank account" />
                        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                            <div style={{ flex: '1 1 auto' }}>

                                <div className='text-container' style={{ padding: 7 }}>
                                    {/* <div>
                            <span className='small-header'>Know your Customer</span>
                        </div> */}
                                    <div>
                                        {/* <span className='small-text'>Enter your full name as seen on your bank payment details</span> */}
                                        <span className='small-text'>Note: Please dont not enter anything crypto, BTC, RMB etc... <br /> on the payment narration.  </span>
                                    </div>

                                </div>


                                <div >
                                    <div className='input-cover'>

                                        <div style={{ margin: 10, padding: 10 }}>

                                            <div className='lableContaine'>
                                                <span className='filedLabl' style={{ alignItems: 'flex-end' }}>Total Payable Amount</span>
                                            </div>

                                            <div className='column ' style={{}}>
                                                <span className='currency-name' style={{ fontWeight: 'normal' }}>NGN</span>
                                                <div className='text-input' type="number" placeholder="Max 100,000" style={{ fontWeight: 'bold' }} >{exponent(exAmount, 2)}</div>
                                            </div>
                                            <div>Fee: NGN {exponent(fee, 2)}</div>

                                        </div>

                                        <div className='grid'>

                                            <div className='column border-bottom' style={{ justifyContent: 'space-between', margin: 10, padding: 10 }}>
                                                <div>
                                                    <span>BankName</span>
                                                </div>

                                                <div className='details-container'>
                                                    <span>{data && Bankname}</span>
                                                </div>
                                            </div>


                                            <div className='column border-bottom' style={{ justifyContent: 'space-between', margin: 10, padding: 10 }}>
                                                <div>
                                                    <span>Account Number</span>
                                                </div>

                                                <div className='column  details-container' onClick={() => copyToAccount(AccountNumber)}>
                                                    <span>{data && AccountNumber}</span>
                                                    <i className='fa fa-copy'></i>
                                                </div>
                                            </div>

                                            <div className='column border-bottom' style={{ justifyContent: 'space-between', margin: 10, padding: 10 }}>
                                                <div>
                                                    <span>Account Name</span>
                                                </div>

                                                <div className='column details-container' onClick={() => copyToAccount(AccountName)}>
                                                    <span>{data && AccountName}</span>
                                                    <i className='fa fa-copy'></i>
                                                </div>
                                            </div>

                                            <div className='column border-bottom' style={{ justifyContent: 'space-between', margin: 10, padding: 10 }}>
                                                <div>
                                                    <span>Note / Narration</span>
                                                </div>

                                                <div className='details-container' onClick={() => copyToAccount(orderId)}>
                                                    <span>{orderId} </span>
                                                    <i className='fa fa-copy'></i>
                                                </div>
                                            </div>


                                            <div className='column ' style={{ justifyContent: 'space-between', margin: 13, padding: 10 }}>
                                                <div>
                                                    <span>Payment Duration</span>
                                                </div>

                                                <div className='details-container'>
                                                    <span>24-38hrs</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <textarea value={summary} onChange={handleSummaryChange} placeholder="Payment Summary" />
                    <button onClick={handleNext}>Next</button>
                    <button onClick={handlePrev}>Back</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='Button-container' style={{ alignSelf: 'flex-end' }}>
                            <button className='btn-continue btn-bg' onClick={() => onSubmit()}>I've made my deposit</button>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
        </div>
    )
}
