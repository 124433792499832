import React from "react";
import '../../style/widget.css'; // Import CSS file for styling

const Loader = () => {
    return (
        <div className="loaderModal">
        <div className="loader"></div>
      </div>
    );
};

export default Loader;