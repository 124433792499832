import React from 'react';

import '../../style/widget.css'; // Import CSS file for styling

const Header = ({ title, onBackClick, icon, size }) => {
    return (
        <div className="header">

            {icon ? <div className="backIcon" onClick={onBackClick}> {/* Add onClick event handler */}
                {/* <FaAngleLeft style={{ fontSize: '28px', color: '#999',  marginTop:10}} /> */}
                <i className="fa fa-angle-left" style={{ fontSize: '28px', color: '#999', }} ></i>
            </div> : <div className="backIcon" />}
            <div className="title" style={{ fontSize: size ? size : 17 }}>
                {title}
            </div>
            <div className="dummyDiv"></div> {/* Add a dummy div for alignment */}
        </div>
    );
};

export default Header;