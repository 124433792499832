import React, { useEffect, useState } from "react"
import Step1 from "./wedgetSteps/Step1";
// import Step2 from "./wedgetSteps/Step2";
import Step3 from "./wedgetSteps/Step3";
import Step4 from "./wedgetSteps/Step4";
import StepAliPay from "./wedgetSteps/StepAliPay";
import exponent from "./controller/exponent";
import Header from "./controller/header";
import Login from "./Authentication/login";
import Loader from "./controller/Loader";
import axios from "axios";
import { nanoid } from 'nanoid';
//     () => {
//     // Check if there is a stored current step in local storage
//     const storedStep = localStorage.getItem('paymentWidgetStep');
//     return storedStep ? parseInt(storedStep) : 1; // Use stored step if available, otherwise default to step 1
// }

const AppPayment = () => {
    const activeSteps = [2, 4, 3, 6, 5];
    const [step, setStep] = useState(1);
    // const [currency, setCurrency] = useState('');
    const [amount, setAmount] = useState(100);
    // const [exAmount, setexAmount] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setphoneNumber] = useState('');
    // const [otp, setOtp] = useState('');
    const [summary, setSummary] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    // const [image, setImage] = useState(null)
    const [alipayFullname, setaliPayFullname] = useState(null)
    const [alipayId, setaliPayId] = useState(null);
    const [data, setdata] = useState('');
    const [isRate, setRate] = useState('');
    const [orderId, setOrderId] = useState('');

    useEffect(() => {
        localStorage.setItem('paymentWidgetStep', step.toString());

    }, [step]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://epayout.qashless.app/v1/api/payout-account/rmb-details');
            const response2 = await axios.get('https://epayout.qashless.app/v1/api/payout-account/rmb-fxrate');
            setdata(response.data);
            setRate(response2.data)

        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {


        // Fetch initial data
        fetchData();

        // Set up interval to fetch data every 10 minutes
        const intervalId = setInterval(fetchData, 10 * 60 * 1000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array to run effect only once on mount


    const handleNext = () => {



        if (activeSteps.includes(step)) {
            setIsLoading(true);

            setTimeout(() => {
                setIsLoading(false);
                setStep(step + 1);
                // setStep((prevStep) => {
                //     const newStep = prevStep + 1;
                //     localStorage.setItem('paymentWidgetStep', newStep.toString()); // Save the new step to local storage
                //     return newStep;
                // });
            }, 2000);
        } else {
            // setStep((prevStep) => {
            //     const newStep = prevStep + 1;
            //     localStorage.setItem('paymentWidgetStep', newStep.toString()); // Save the new step to local storage
            //     return newStep;
            // });

            setStep(step + 1);

            setIsLoading(false); // Ensure isLoading is set to false for steps not in activeSteps
        }


    };

    const handlePrev = () => {
        // if (step == 3) {
        //     setStep(step - 2);
        // } else {
        setStep(step - 1);
        //}
    };

    // const handleCurrencyChange = (e) => {
    //     setCurrency(e.target.value);
    // };

    const handleAmountChange = (value) => {
        setAmount(value);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // const handleOtpChange = (e) => {
    //     setOtp(e.target.value);
    // };

    const handleSummaryChange = (e) => {
        setSummary(e.target.value);
    };


    const handleAlipayNameChange = (e) => {
        setaliPayFullname(e.target.value);
    };
    const handleAliIDChange = (e) => {
        setaliPayId(e.target.value)
    };
    const handlePhoneChange = (e) => {
        setphoneNumber(e.target.value)
    }


    // function generateOrderId() {
    //     return 'ORD-' + Date.now() + '-' + Math.floor(Math.random() * 10000);
    //   }
    const generateOrderId = () => {
        // Generate a timestamp-based number and append a random number to ensure it's more than 15 digits
        const timestamp = Date.now(); // This gives a 13-digit number
        const randomPart = Math.floor(Math.random() * 1000); // Adding a 5-digit random number
        const newOrderId = timestamp.toString() + randomPart.toString();
        setOrderId(newOrderId);

    };

    const handleSubmit = async () => {

        try {
            const { Rate, fee } = isRate[0] || {}

            const isFee = parseInt(fee)
            const exAmount = parseFloat(amount * Rate + isFee);
            setIsLoading(true);

            const response = await axios.post("https://epayout.qashless.app/v1/api/payout-account/post-order", {
                fullname: name,
                phone: phoneNumber,
                email: email,
                aliname: alipayFullname,
                alidpayid: alipayId,
                amount: exponent(amount, 2),
                ordId: orderId,
                fee: exponent(fee, 2),
                image: `${previewUrl}`,
                rate: Rate,
                total: exponent(exAmount, 2),
            });

            if (response) {

                setIsLoading(false);
                handleNext();
                return;
            }
        } catch (e) {
            console.log(e)
            // return ;
        }
    }

    const onEndOrder = () => {
        setStep(1)
        fetchData();

        setName('');
        setEmail('');
        setphoneNumber('');
        // const [otp, setOtp] = useState('');
        setSummary('');
        setPreviewUrl(null);
        // const [image, setImage] = useState(null)
        setaliPayFullname(null)
        setaliPayId(null);
        setOrderId('');
    }

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <Step1
                        handleAmountChange={handleAmountChange}
                        amount={amount}
                        handleNext={handleNext}
                        isLoading={isLoading}
                        isRate={isRate}

                    />
                );
            case 2:
                return (

                    <Login
                        setIsLoading={setIsLoading}
                        isLoading={isLoading}
                        name={name}
                        email={email}
                        phoneNumber={phoneNumber}
                        handleEmailChange={handleEmailChange}
                        handleNameChange={handleNameChange}
                        handleChangePhoneNumber={handlePhoneChange}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                    />

                );
            case 3:
                return (

                    <StepAliPay
                        previewUrl={previewUrl}
                        setPreviewUrl={setPreviewUrl}
                        setIsLoading={setIsLoading}
                        // setImage={setImage}
                        isLoading={isLoading}
                        alipayFullname={alipayFullname}
                        alipayId={alipayId}
                        handleAlipayNameChange={handleAlipayNameChange}
                        handleAliIDChange={handleAliIDChange}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                    />

                );
            case 4:
                return (
                    <Step3
                        isLoading={isLoading}
                        name={name}
                        email={email}
                        handleEmailChange={handleEmailChange}
                        handleNameChange={handleNameChange}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                    />
                );
            case 5:
                return (
                    <Step4
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                        summary={summary}
                        amount={amount}
                        phone={phoneNumber}
                        handleSummaryChange={handleSummaryChange}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                        data={data}
                        isRata={isRate}
                        orderId={orderId}
                        generateOrderId={generateOrderId}
                    />


                );

            // case 5:
            //     return (
            //         <StepAliPay isLoading={isLoading} summary={summary} exAmount={exAmount} handleSummaryChange={handleSummaryChange} handlePrev={handlePrev} handleNext={handleNext} />
            //     );
            case 6:
                return (
                    <div className='main-section' >
                        <div className='mainContainer' >
                            <div className='content' style={{ paddingTop: 0 }}>
                                <div className='parentContainer details-cover'>

                                    <Header onBackClick={handlePrev} title="Confirm Transaction" />



                                    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '50vh' }}>
                                        <div style={{ flex: '1 1 auto', alignItems: 'center', justifyContent: 'center', }}>
                                            <div>
                                                <h4 style={{ textAlign: 'center' }}>Thank you for using Yuan Pay</h4>
                                            </div>
                                            <div style={{ textAlign: 'center' }}>
                                                <p>Our team is verifying your payment. </p>
                                                <p>kindly click on the Whatsapp icon to send us your proof of payment </p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='Button-container' style={{ alignSelf: 'flex-end' }}>
                                        <button className='btn-continue btn-bg' onClick={() => onEndOrder()}>Confirm Payment</button>
                                        {/* <button onClick={handlePrev}>Back</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            // case 6:
            //     return (
            //         <div>
            //             <h2>Payment Completed</h2>
            //             <p>Thank you for your payment!</p>
            //             <button onClick={() => setStep(1)}>Start Over</button>
            //         </div>
            //     );
            default:
                return null;
        }
    };


    return (
        <div className="container">
            <div class="payment__info"><div class="payment__business-name">
                <div style={{ height: 80, width: '' }}>
                    <img alt="https://qashless.app/icons/yuanpayLogo.png" src='https://qashless.app/icons/yuanpayLogo.png' style={{ height: '100%', width: '100%' }} />
                </div>
            </div>
                <div class="p sm payment__description">Buy anytime it's fast and easy all you need is follow the easy Steps</div>

            </div>
            {renderStep()}
            {isLoading && <Loader />}
            <footer className='footerContainer' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="column">
                    <span style={{ fontSize: 12, color: "#fff", marginLeft: 5 }}> Power by Schola Technology Limited &nbsp;</span>
                    <img alt="https://qashless.app/icons/yuanpayLogo.png" src="https://qashless.app/icons/ScholTechICon.png" height={25} width={25} style={{ borderRadius: 50 }} />
                </div>
            </footer>

        </div>
    )
}

export default AppPayment;